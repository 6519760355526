<!-- eslint-disable -->
<template>
    <div id="home" class="main">
        <div id="buttonReset" @click="modalShow = true" style="margin: 15px;">
            <img src="@/assets/img/qrcode.png" id="homeQrCode" class="float-right"/>
        </div>
        <b-modal v-model="modalShow" id="modalReset" :title="$t('warning')" @ok="resetDemo">
            {{$t('resetConfirmation')}}
        </b-modal>

        <div id="share" @click="$parent.share" style="margin: 15px;">
            <img src="@/assets/img/share_black.png" id="pictoShare" class="float-right"/>
        </div>
        <header class="welcomeBanner">
            <h1>
                <img :src="$parent.logoURL" id="homeLogo"/>
            </h1>
            <p class="baseline">{{ $t('welcome')}}</p>
        </header>
        <main>
            <div class="canvas" aria-hidden="true">
                <svg id="sac" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 316.24 228" preserveAspectRatio="xMinYMin meet">
                    <defs>
                        <polygon id="star" points="14.61 0 19.13 9.15 29.23 10.62 21.92 17.74 23.65 27.8 14.61 23.05 5.58 27.8 7.31 17.74 0 10.62 10.1 9.15 14.61 0"/>
                        <radialGradient id="7284963a-2d15-44d1-b5ec-7aa285712508" data-name="Dégradé sans nom 11" cx="161.19" cy="188.4" r="50.66" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#333"/>
                            <stop offset="0.44" stop-color="#3c3c3c" stop-opacity="0.96"/>
                            <stop offset="0.65" stop-color="#5a5a5a" stop-opacity="0.81"/>
                            <stop offset="0.81" stop-color="#8e8e8e" stop-opacity="0.55"/>
                            <stop offset="0.94" stop-color="#d7d7d7" stop-opacity="0.19"/>
                            <stop offset="1" stop-color="#fff" stop-opacity="0"/>
                        </radialGradient>
                        <radialGradient id="6a8fadd8-5c28-43c4-aa02-ec6c6533c2aa" data-name="Dégradé sans nom 9" cx="180.5" cy="15.26" r="6.75" gradientTransform="translate(-62.91 70.78) scale(1.36 1.48)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#ed1e79"/>
                            <stop offset="0.29" stop-color="#ed207a" stop-opacity="0.99"/>
                            <stop offset="0.43" stop-color="#ee277f" stop-opacity="0.96"/>
                            <stop offset="0.54" stop-color="#ef3486" stop-opacity="0.9"/>
                            <stop offset="0.63" stop-color="#f04590" stop-opacity="0.83"/>
                            <stop offset="0.71" stop-color="#f25c9e" stop-opacity="0.72"/>
                            <stop offset="0.79" stop-color="#f478af" stop-opacity="0.6"/>
                            <stop offset="0.85" stop-color="#f79ac3" stop-opacity="0.45"/>
                            <stop offset="0.92" stop-color="#fac1da" stop-opacity="0.28"/>
                            <stop offset="0.98" stop-color="#fdebf3" stop-opacity="0.09"/>
                            <stop offset="1" stop-color="#fff" stop-opacity="0"/>
                        </radialGradient>
                        <radialGradient id="fa52ac5b-a8fe-452f-bf78-17fc18f0f8b3" cx="147.34" cy="15.26" r="6.75" xlink:href="#6a8fadd8-5c28-43c4-aa02-ec6c6533c2aa"/>
                    </defs>
                    <title>ptsac</title>
                    <g id="bg" data-name="bg">
                        <path d="M316.19,171c0-1.17.05-2.33.05-3.51C316.24,75,245.44,0,158.12,0S0,75,0,167.49c0,1.18,0,2.34,0,3.51Z" style="fill: #dcf9c5"/>
                    </g>
                    <g id="shadow" data-name="shadow">
                        <ellipse cx="161.19" cy="188.4" rx="70.52" ry="8.95" style="stroke-miterlimit: 10;opacity: 0.15;stroke: url(#7284963a-2d15-44d1-b5ec-7aa285712508)"/>
                    </g>
                    <g id="all-stars"></g>
                    <g id="holder" data-name="holder">
                        <path d="M137.56,52.94S135.56,28,161.08,28s23.49,25.57,23.49,25.57L179,53.9s-.23-21.2-17.91-21c-19.93.26-17.24,20.48-17.24,20.48Z" style="fill: #590707"/>
                    </g>
                    <g id="l-arm" data-name="l-arm">
                        <path d="M274.58,90c-11.92,17.31-49.09,21.79-64.65,17.13,4.86,3.08,1.43,7.68,8.25,9.9,4.52,1.48,13.71-.92,18.48-1.91a89,89,0,0,0,36.88-17l.12-.42a14.2,14.2,0,0,1,2.75-1.44A54.06,54.06,0,0,1,274.58,90Z" style="fill: #590707"/>
                        <path id="l-hand" d="M307.47,81.56c-1.63-4.15-12.25,2.59-15.43-.19,1-1.56,12-9.22,10.29-11.19-4-4.72-15.05,8.12-18.8,8.36-2.19.13.16-9.4-5.53-6.78-2.77,1.28-1.31,9.11-1.24,10.28l-1.84,5.77a21.85,21.85,0,0,1-1.43,2.29q1.11,3,2,6.09c5.55-2.23,13.84-2.64,18.07-2.27,2.53.21,11.17,4.57,13,1.56,3.29-5.55-11.36-6.46-11.85-7.17C292.55,85.2,309.17,85.9,307.47,81.56Z" style="fill: #590707"/>
                    </g>
                    <g id="r-arm" data-name="r-arm">
                        <path d="M47,188.24c3.79,2.34,8.24-9.42,12.45-9.1.12,1.86-4,14.55-1.51,15.12,6,1.36,7.16-15.53,10-18,1.67-1.43,5.51,7.6,8.49,2.09,1.45-2.68-4.42-8.07-5.18-9l-2-5.72c-3.33-21.4,25.34-49.2,41.2-55.05-5.73.46-5.75-5.28-12.53-3-4.51,1.53-10.42,9-13.64,12.62A88.82,88.82,0,0,0,65,154.06l.15.4c-1.93,5.88-10.18,12.88-14.47,15.56-2.16,1.34-11.69,3-11.31,6.52.7,6.42,13-1.65,13.78-1.37C56.69,176.38,43,185.79,47,188.24Z" style="fill: #590707"/>
                        <path d="M54.46,180.09c3.8,2.35,3-.23,4.45-1.1.13,1.86-4,14.55-1.51,15.12,6,1.37,7.17-15.53,10-18,1.67-1.42,1.7,2.31,4.09-1.29.84-1.27,0-4.68-.77-5.57l-2-5.73c-3.32-21.39,25.34-49.19,41.21-55-5.74.46-5.75-5.28-12.54-3-4.5,1.53-10.42,9-13.64,12.63a88.72,88.72,0,0,0-19.29,35.73l0,0c-1.93,5.87-10.06,13.33-14.35,16-2.16,1.35-.65,4-.65,4,2,2,3,1,3,1C56.08,176.06,50.5,177.65,54.46,180.09Z" style="fill: #590707"/>
                    </g>
                    <g id="sac" data-name="sac">
                        <path d="M110.24,46.82s29.64,5.37,55.76,5.37a189.87,189.87,0,0,0,45.63-5.37S234.24,168.15,229.57,187c-64,6.27-121.67,2.68-134.15,0S110.24,46.82,110.24,46.82Z" style="fill: #f9d87a"/>
                        <g id="45cb6610" data-name="Calque 11">
                            <path d="M110,47S85,190,142,190c-49,0-48-4-48-4S86,168,110,47Z" style="opacity: 0.04"/>
                        </g>
                        <ellipse cx="183.23" cy="93.38" rx="10.07" ry="8.95" style="opacity: 0.2;fill: url(#6a8fadd8-5c28-43c4-aa02-ec6c6533c2aa)"/>
                        <ellipse cx="138.01" cy="93.38" rx="10.07" ry="8.95" style="opacity: 0.2;fill: url(#fa52ac5b-a8fe-452f-bf78-17fc18f0f8b3)"/>
                        <path d="M117.11,47.94s21.57,5.6,44,5.6,45.81-5.6,45.81-5.6-18.95,9.62-45.81,9.62C130.17,57.56,117.11,47.94,117.11,47.94Z" style="fill: #fff;opacity: 0.39"/>
                    </g>
                    <g id="eyes" data-name="eyes">
                        <g>
                            <circle cx="176.07" cy="82.86" r="6.04" style="fill: #fff;opacity: 0.8"/>
                            <circle cx="145.62" cy="82.86" r="6.04" style="fill: #fff;opacity: 0.8"/>
                            <circle cx="145.85" cy="84.43" r="3.81"/>
                            <circle cx="176.29" cy="84.43" r="3.81"/>
                            <polygon points="176.29 80.4 176.29 84.43 179.87 80.4 176.29 80.4" style="fill: #fff"/>
                            <polygon points="145.84 80.4 145.84 84.43 149.43 80.4 145.84 80.4" style="fill: #fff"/>
                        </g>
                    </g>
                    <g id="brows" data-name="brows">
                        <path d="M172.83,67.73A18.52,18.52,0,0,1,184,69.09" style="fill: none;stroke: #6d2201;stroke-miterlimit: 10"/>
                        <path d="M138.19,70.35A13.44,13.44,0,0,1,148.61,68" style="fill: none;stroke: #6d2201;stroke-miterlimit: 10"/>
                    </g>
                    <g id="mouth" data-name="mouth">
                        <path d="M153.2,103.71a7.06,7.06,0,0,0,3.59,3.25,7.63,7.63,0,0,0,2.26.55,10.17,10.17,0,0,0,2.34,0,11.08,11.08,0,0,0,1.15-.21c.36-.12.72-.24,1.05-.37.66-.3,1.26-.51,1.87-.74a11.45,11.45,0,0,0,3.92-2.48l.39.07c.69,2.11-.43,4.48-2.25,5.85a6.38,6.38,0,0,1-3.08,1.17c-.54,0-1.06,0-1.58,0s-1-.06-1.51-.12a14,14,0,0,1-2.93-.62,10,10,0,0,1-2.67-1.43,7.71,7.71,0,0,1-2.94-4.87Z" style="fill: #6d2201"/>
                    </g>
                </svg>
            </div>
            <div class="explain">
                <p>
                    <router-link :to="this.base + '/scan'">
                        <img src="@/assets/img/barcode.svg" id="homeBarcode" alt="Bar-code example"/>
                    </router-link>
                </p>
                <p>{{ $t('infoScan')}}</p>
            </div>
            <div class="homeFooter">
                <span>v{{version}}</span>
            </div>
        </main>
    </div>
</template>

<script>
    /* eslint-disable */
    import {isNotNull} from "@/assets/js/Utils"
    import "@/assets/utils/addtohomescreen.css"
    import "@/assets/utils/addtohomescreen.js"
    var Utils = require("@/assets/js/Utils")
    var Basket = require("@/assets/js/Basket")

    export default {
        name: "Home",
        data () {
            return {
                version: "",
                scantype: localStorage.getItem("DKC_scanType"),
                modalShow: false,
                base: localStorage.getItem("DKC_base")
            }
        },
        mounted(){
            this.version = Utils.getConfig().pwaVersion;
            const svgCanvas = document.getElementById('all-stars');
            const hand = document.getElementById('l-hand');
            const arm = document.getElementById('l-arm');
            const rArm = document.getElementById('r-arm');
            const brows = document.getElementById('brows');
            const mouth = document.getElementById('mouth');
            const handler = document.getElementById('holder');

            hand.classList.add('l-hand');
            arm.classList.add('l-arm');
            rArm.classList.add('r-arm');
            brows.classList.add('eyeBrows');
            mouth.classList.add('mouth');
            handler.classList.add('handler');

            window.setTimeout(function () {
                rArm.querySelectorAll('path')[0].style.opacity = "0";
                rArm.querySelectorAll('path')[1].style.opacity = "1";
            }, 3000);
            window.setTimeout(function () {
                rArm.querySelectorAll('path')[1].classList.add('point');
            }, 4000);

            svgCanvas.insertAdjacentHTML('afterbegin', '<use xlink:href=\"#star\" class=\"color3 size2\" x=\"40\" y=\"50\" />');
            svgCanvas.insertAdjacentHTML('afterbegin', '<use xlink:href=\"#star\" class=\"color2 size1\" x=\"25\" y=\"5\" />');
            svgCanvas.insertAdjacentHTML('afterbegin', '<use xlink:href=\"#star\" class=\"color1 size3\" x=\"80\" y=\"15\" />');
            svgCanvas.insertAdjacentHTML('afterbegin', '<use xlink:href=\"#star\" class=\"color1 size1\" x=\"110\" y=\"25\" />');
            svgCanvas.insertAdjacentHTML('afterbegin', '<use xlink:href=\"#star\" class=\"color3 size2\" x=\"115\" y=\"0\" />');
            svgCanvas.insertAdjacentHTML('afterbegin', '<use xlink:href=\"#star\" class=\"color2 size3\" x=\"245\" y=\"120\" />');
            svgCanvas.insertAdjacentHTML('afterbegin', '<use xlink:href=\"#star\" class=\"color2 size2\" x=\"200\" y=\"20\" />');
        },
        methods: {
            resetDemo(){
                Utils.clearCacheExceptCustomer(this);
                Basket.clearBasket();
                this.$router.push('/need/scan?needParameters=true');
            }
        },
        created() {
            var isStandalone = Utils.isStandalone();

            var now = Date.now();
            var lastShow = localStorage.getItem("DKC_lastShowHelper");
            var deviceType = localStorage.getItem("DKC_deviceType");

            if (!isNotNull(lastShow) || (isNotNull(lastShow) && !((now - parseInt(lastShow)) < (60 * 60000)))) { //check if last display is more than 1 hour
                if(!isStandalone && !this.$parent.modalShow && deviceType == "iphone"){
                    addToHomescreen.removeSession();
                    addToHomescreen({
                        startDelay: 0,
                        lifespan: 45,
                        displayPace: 0
                    });
                    localStorage.setItem("DKC_lastShowHelper", now);
                }
            }

            /*
            if(isNotNull(window.location.pathname.indexOf("DKS"))){
                window.location.replace(window.location.origin + localStorage.getItem("DKC_base"));
            }
*/
            this.$parent.checkDemonstrationLogo();
        }
    }
</script>

<style scoped>
</style>